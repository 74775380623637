import { DropdownMenu, MobileMenuProps, NavMenuType } from '@/types/layout';
import { BEGINNER_WORKSHOP_LINK, BUSINESS_BUILDER_LINK } from './sourcing-marketplace';
import { LinkTarget } from '../types/menu';
import { STATIC_FILE_URL } from '@/shared/constants';

export const DROPDOWN_MENU_CONTENT: DropdownMenu = {
  platform: {
    pillars: [
      {
        path: '/platform/ideation-and-brand-setup',
        title: 'Ideation & Brand Setup',
        class: 'ideation',
        description:
          'Market research and design tools to help you expand product lines and bring products to market fast.',
        isAnimatedWand: true,
        icon: `${STATIC_FILE_URL}/33bf2902a84fbfd1048adc49b4459085.svg`,
      },
      {
        path: '/platform/sourcing-and-production',
        title: 'Sourcing & Production',
        class: 'sourcing',
        description:
          'Software to manage existing partners & access to 1200+ more vetted product, packaging, and merch manufacturers to lower costs.',
        icon: `${STATIC_FILE_URL}/9065302481b4347de8cbfe8df9365277.svg`,
        hoverIcon: `${STATIC_FILE_URL}/57f030787d927949419c82c24fcc4f60.svg`,
      },
      {
        path: '/platform/logistics-and-fulfillment',
        title: 'Logistics & Fulfillment',
        class: 'logistics',
        description:
          'A private 3PL designed for scaling brands across DTC, wholesale, and retail. Using Pietra, brands save 30%+ on commodity services.',
        icon: `${STATIC_FILE_URL}/217499880bb001e11297dbaf17ea083f.svg`,
        hoverIcon: `${STATIC_FILE_URL}/909f749cdb877cfe790f0f33d6b6bdaa.svg`,
      },
      {
        path: '/platform/sales-and-marketing',
        title: 'Sales & Marketing',
        class: 'sales',
        description:
          'Instantly add 200+ sales channels, access popular e-commerce software for free, and offload CX and paid marketing to Pietra.',
        icon: `${STATIC_FILE_URL}/ad44efa21903b617af9223a5ab35a85e.svg`,
        hoverIcon: `${STATIC_FILE_URL}/c777e5dd7a587b8eaa2c53e5bedcac8c.svg`,
      },
    ],
    apps: [
      {
        title: 'Pietra Returns',
        info: ['Automate returns for your brand and upsell customers.'],
        path: '/platform/returns',
      },
      {
        title: 'Pietra Affiliate Marketing',
        info: ['Create UTM codes for influencers and measure ROI.'],
        path: '/platform/affiliate-marketing',
      },
      {
        title: 'Pietra SMS Marketing',
        info: ['Send smart AI campaigns, see faster conversions, and achieve higher ROI.'],
        path: '/platform/sms-marketing',
      },
      {
        title: 'Pietra Print on Demand',
        info: ['Start promoting your idea with custom merch.'],
        path: '/platform/print-on-demand',
      },
    ],
  },
  useCases: {
    caseStudy: {
      brand: 'Arrae',
      link: `${STATIC_FILE_URL}/c76df44b4c313cd9c263091df20e5fac.pdf`,
      image: `${STATIC_FILE_URL}/dd4714d5ff2741e8b6c4df07bcd2e5a5.png`,
      logo: `${STATIC_FILE_URL}/bd4460378441ea2fb0493f236ed38a5d.svg`,
      description: 'Discover how brands are growing their business with Pietra.',
    },
    sections: [
      {
        title: 'By Stage',
        items: [
          {
            class: 'new-brands-section',
            path: '/new-brands',
            icon: `${STATIC_FILE_URL}/bc87de97aa72d6f22276cc8e229ccd8f.svg`,
            hoverIcon: `${STATIC_FILE_URL}/c654c657d03147a1835e599276b8248b.svg`,
            title: 'For New Brands',
            description:
              'The fastest, easiest, and most affordable way to start your brand. Get up and running in no time.',
          },
          {
            class: 'existing-brands-section',
            path: '/existing-brands',
            icon: `${STATIC_FILE_URL}/18d84f9eb75c580afb379c36c8f127fc.svg`,
            hoverIcon: `${STATIC_FILE_URL}/0d9a4541ec4b0f0ea1511a8259bb6623.svg`,
            title: 'For Existing Brands',
            description:
              "Scale your brand & save 30-50% with Pietra. Pietra's members-only products and services gives brands competitive pricing on core software and services.",
          },
        ],
      },
    ],
  },
};

const { Self, Blank } = LinkTarget;

export const START_BUSINESS_MENU = [
  {
    title: 'Sourcing',
    desc: 'Lower costs by tapping into a trusted supplier network.',
    imgSrc: '/images/head-drop/sales1.svg',
    href: '/platform/sourcing-and-production',
    class: 'growth-item-0',
    target: Self,
  },
  {
    title: 'Fulfillment',
    desc: 'Get members only pricing on shipping and order fulfillment for your brand.',
    imgSrc: '/images/head-drop/sales3.svg',
    href: '/platform/logistics-and-fulfillment',
    class: 'growth-item-1',
    target: Self,
  },
  {
    title: 'Marketing Tools',
    desc: 'Add sales channels and get access to email, SMS, and influencer marketing tools.',
    imgSrc: '/images/head-drop/sales2.svg',
    href: '/platform/sales-and-marketing',
    class: 'growth-item-2',
    target: Self,
  },
  {
    title: 'Start your dream brand with Pietra',
    desc: "See how Pietra's platform can help you start your brand.",
    href: '/new-brands',
    class: 'start-li-four',
    target: Self,
  },
];

export const SCALE_BUSINESS_MENU_GROWTH = [
  {
    title: 'Sourcing',
    desc: 'Lower costs by tapping into a trusted supplier network.',
    imgSrc: '/images/head-drop/sales1.svg',
    href: '/platform/sourcing-and-production',
    class: 'growth-item-0',
    target: Self,
  },
  {
    title: 'Fulfillment',
    desc: 'Get members only pricing on shipping and order fulfillment for your brand.',
    imgSrc: '/images/head-drop/sales3.svg',
    href: '/platform/logistics-and-fulfillment',
    class: 'growth-item-1',
    target: Self,
  },
  {
    title: 'Marketing Tools',
    desc: 'Add sales channels and get access to email, SMS, and influencer marketing tools.',
    imgSrc: '/images/head-drop/sales2.svg',
    href: '/platform/sales-and-marketing',
    class: 'growth-item-2',
    target: Self,
  },
  {
    title: 'Learn about scaling with Pietra',
    desc: "See how Pietra's platform lowers cost and increases profits.",
    href: '/existing-brands',
    class: 'scale-li-four',
    target: Self,
  },
];

export const SCALE_BUSINESS_MENU_ARRAE = [
  {
    titleImg: '/images/head-drop/sale5.png',
    desc: 'Discover how brands are growing their business with Pietra.',
    imgSrc: '/images/head-drop/sale4.png',
    href: `${STATIC_FILE_URL}/c76df44b4c313cd9c263091df20e5fac.pdf`,
    class: 'arrae-item-0',
    target: Blank,
  },
];

export const START_BUSINESS_MENU_APP = [
  {
    title: 'Design products with AI',
    href: '/platform/design-studio',
    class: 'design-studio',
    target: Self,
  },
  {
    title: 'Start with a step-by-step business guide',
    href: 'https://builder.pietrastudio.com/',
    class: 'business-builder',
    target: Blank,
  },
  {
    title: 'Create a brand kit for your business',
    href: '/platform/business-kit',
    class: 'brand-kit',
    target: Self,
  },
  {
    title: 'Design products with print on demand',
    href: '/platform/print-on-demand',
    class: 'print-on-demand',
    target: Self,
  },
];

export const SCALE_BUSINESS_MENU_APP = [
  {
    title: 'Email Capture',
    href: '/platform/email-capture',
    class: 'audience-capture',
    target: Self,
  },
  {
    title: 'Affiliate Marketing',
    href: '/platform/affiliate-marketing',
    class: 'affiliate-marketing',
    target: Self,
  },
  {
    title: 'Print on Demand',
    href: '/platform/print-on-demand',
    class: 'print-on-demand',
    target: Self,
  },
  {
    title: 'SMS Marketing',
    href: '/platform/sms-marketing',
    class: 'sms-marketing',
    target: Self,
  },
  {
    title: 'Analytics',
    href: '/platform/analytics',
    class: 'analytics',
    target: Self,
  },
];

export const COMMUNITIY_MENU_SERVICE = [
  {
    title: 'AI tools for e-commerce',
    desc: 'Free tools to kickstart ideas and improve listings',
    imgSrc: '/images/head-drop/communit1.png',
    href: '/ai-tools',
    target: Self,
  },
  {
    title: 'Step-by-step guide',
    desc: 'Start a profitable business with Pietra',
    imgSrc: '/images/head-drop/communit2.png',
    href: BUSINESS_BUILDER_LINK,
    target: Blank,
  },
];

export const SOLUTIONS = [
  {
    title: 'Starting a brand',
    desc: 'For first timers, read about how Pietra gives you everything you need, cheaper, and easier than doing it yourself.',
    imgSrc: 'https://static.pietrastudio.com/public/file_uploads/15604f122591bd53f1cc39f9f441e450.png',
    href: '/new-brands',
  },
  {
    title: 'Marketing & operations tools',
    desc: 'Access popular marketing tools for your brand at steep discounts just for being a member.',
    imgSrc: 'https://static.pietrastudio.com/public/file_uploads/f962cef03528b3f832d70f8c7f60c5c7.png',
    href: '/platform/sales-and-marketing',
  },
  {
    title: 'Scaling your brand',
    desc: 'See how Pietra’s platform lowers cost and increases profits.',
    imgSrc: 'https://static.pietrastudio.com/public/file_uploads/6d166a32093071a10e8b34cef60262a0.png',
    href: '/existing-brands',
  },
  {
    title: 'Save big with Pietra’s 3PL',
    desc: 'See how much your brand can save vs. competitor offerings. No minimums, no lock in, all upfront pricing.',
    imgSrc: 'https://static.pietrastudio.com/public/file_uploads/7d0b0f53c77a4459a52c249f8c724c1e.png',
    href: '/platform/logistics-and-fulfillment',
  },
];

export const FREE_TOOLS = [
  {
    title: 'Start your business with a step-by-step guide',
    desc: 'Start a profitable business with Pietra Business Builder.',
    imgSrc: 'https://static.pietrastudio.com/public/file_uploads/63065f68418c2d65c9767eae32b59c0d.png',
    href: BUSINESS_BUILDER_LINK,
    class: 'item-0',
    target: Blank,
  },
  {
    title: 'Create a free merch shop with no inventory',
    desc: 'Upload graphics and make merch with Pietra Print on Demand.',
    imgSrc: 'https://static.pietrastudio.com/public/file_uploads/31924ad7ed1d5d2eadf30d8a491f54ef.png',
    href: '/platform/print-on-demand',
    class: 'item-1',
  },
  {
    title: 'Generate custom brand kits for business with AI',
    desc: 'Use Pietra Brand Kits to create your brand guidelines.',
    imgSrc: 'https://static.pietrastudio.com/public/file_uploads/1a3dfbf81a3ea71e43c70b53b357caa4.png',
    href: '/platform/business-kit',
    class: 'item-2',
  },
];

export const COMMUNITIY_MENU_SOCIAL_ACCOUNT = [
  {
    title: 'Instagram',
    href: 'https://www.instagram.com/shoppietra/',
    class: 'community-instagram',
    target: Blank,
  },
  {
    title: 'TikTok',
    href: 'https://www.tiktok.com/@shoppietra',
    class: 'community-tiktok',
    target: Blank,
  },
];

export const MOBILE_MENU: MobileMenuProps[] = [
  { title: 'Products', type: NavMenuType.E_COMMERCE_SUITE },
  { title: 'Solutions', type: NavMenuType.SOLUTIONS },
  { title: 'Resources', type: NavMenuType.RESOURCES },
  { title: 'Pricing', href: '/pricing', type: NavMenuType.LINK },
];

export const DARK_ROUTER_PATHS = [
  '/platform/design-studio',
  '/platform/print-on-demand',
  '/platform/affiliate-marketing',
  '/platform/sourcing-and-production',
  '/new-brands',
  '/existing-brands',
  '/ai-tools',
  '/ai-tools/ai-clothing-model-generator',
  '/ai-tools/ai-fabric-pattern-changer',
  '/ai-tools/photo-background-changer',
  '/ai-tools/clothes-color-changer',
  '/ai-tools/ai-t-shirt-generator',
  '/careers',
  '/plans-and-pricing',
  '/pietra-partners-new',
  '/dropship',
];
